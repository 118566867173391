<template>
  <div>
    <template v-if="!isExpandedView">
      <div class="mr-n3">
        <b-card class="engagement-dashboard-card mb-2" no-body v-for="(project, index) in getProjects.slice(0, 2)" :key="`${index}-${project.id}`">
          <div class="engagement-dashboard-card-body">
            <div>
              <b-card-img :src="project['image-url']" :alt="project['image-caption']" class="rounded-0"></b-card-img>
            </div>
            <div class="px-3">
              <a :href="project.link" v-srOnlyNewWindow target="_blank" class="title theme-font-bold">{{ project.name }}</a>
            </div>
          </div>
        </b-card>
      </div>
    </template>
    <template v-else>
      <b-row>
        <b-col>
          <b-form-group>
            <div class="floating-input-field">
              <b-form-input type="text" id="search-forms" class="form-control" placeholder="Search projects" v-model="searchProjects"></b-form-input>
              <label for="search-forms">{{ translateLanguage('Txt_Engagement_widget_Search_projects') }}</label>
              <BIconSearch class="input-placeholder-icon" />
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!getProjectsByGroups.length">
        <b-col cols="12" class="theme-font-bold">{{ `No projects found with a keyword "${searchProjects}"` }}</b-col>
      </b-row>
      <b-row no-gutters class="mx-n1 engagement-project-groups" v-for="(projectGroup, index) in getProjectsByGroups" :key="`pGroup-${index}`">
        <b-col cols="12" class="px-1" v-if="projectGroup.projectGroupTitle">
          <h4 class="py-2">{{ projectGroup.projectGroupTitle }}</h4>
        </b-col>
        <b-col lg="6" class="px-1 engagement-projects" v-for="(project, pIndex) in projectGroup.projects" :key="`${pIndex}-${project.id}`">
          <b-card class="mb-2 engagement-dashboard-card animated-card" no-body>
            <div class="engagement-dashboard-expanded-card-body">
              <section class="header">
                <div class="img-container">
                  <b-card-img
                    :src="project['image-url']"
                    :alt="project['image-caption']"
                    class="rounded-0 animated-card-image"
                    :style="{
                      objectFit: 'cover',
                      objectPosition: `${project['img-focal-x']}% ${project['img-focal-y']}%`,
                    }"
                  ></b-card-img>
                </div>
                <div class="mx-3">
                  <a :href="project.link" v-srOnlyNewWindow target="_blank" class="title theme-font-bold font-18">{{ project.name }}</a>
                </div>
              </section>
              <main class="px-3 pt-2">
                <section class="description">
                  {{ project.description }}
                </section>
                <section v-if="project['meta-data'] && project['meta-data'].totalStages" class="mt-2 stage font-14">
                  <div class="stage-progress">
                    <span class="current-stage">
                      {{ translateLanguage('Txt_Engagement_widget_Stage') }}: {{ project['meta-data'].activeStages }}
                      {{ translateLanguage('Txt_Engagement_widget_Of') }} {{ project['meta-data'].totalStages }}
                    </span>
                    <span class="stage-name">
                      {{ shortenText(project['meta-data'].stageName, 35, '...') }}
                    </span>
                  </div>
                </section>
              </main>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import { BIconSearch } from 'bootstrap-vue'
import groupBy from 'lodash/groupBy'
import { truncate } from '../../../utilities/filters.js'
export default {
  name: 'EngagementWidgetContent',
  data() {
    return {
      searchProjects: '',
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isExpandedView: {
      type: Boolean,
      default: false,
    },
  },
  components: { BIconSearch },
  computed: {
    getProjects() {
      return this.data && this.data.engagementWidgetProjects && this.data.engagementWidgetProjects.length
        ? this.data.engagementWidgetProjects.filter(
            (project) =>
              project.name.toLowerCase().includes(this.searchProjects.toLowerCase()) ||
              (project['project-tag-list'] &&
                project['project-tag-list'].findIndex((tag) => tag.toLowerCase().includes(this.searchProjects.toLowerCase())) > -1)
          )
        : false
    },
    getProjectsByGroups() {
      const filterProjects = this.data.engagementWidgetProjects.filter(
        (project) =>
          project.name.toLowerCase().includes(this.searchProjects.toLowerCase()) ||
          (project['project-tag-list'] && project['project-tag-list'].findIndex((tag) => tag.toLowerCase().includes(this.searchProjects.toLowerCase())) > -1)
      )
      const groupByProjects = groupBy(filterProjects, 'project-group-sequence')
      const result = []
      for (const [key, value] of Object.entries(groupByProjects)) {
        result.push({
          projectGroupTitle: value[0]['project-group'],
          sequence: Number(key),
          projects: value.sort((a, b) => a.sequence - b.sequence),
        })
      }
      return result.sort((a, b) => a.sequence - b.sequence)
    },
    getStageValue() {
      return (metaData, index) => {
        const eachValue = 100 / metaData.totalStages
        return index + 1 <= metaData.activeStages ? eachValue : 0
      }
    },
    getStageBgColor() {
      return (index) => {
        const decrementCount = 5
        const startColorLightnessValue = 70 // 70 -> Start of progress color #6cc8f9 (hsl(201, 92%, 70%)) lightness value
        const colorLightnessValue = startColorLightnessValue - index * decrementCount
        return {
          backgroundColor: `hsl(201, 92%, ${colorLightnessValue}%)`,
        }
      }
    },
  },
  mounted() {},
  methods: {
    shortenText(text, length, suffix) {
      return truncate(text, length, suffix)
    },
  },
}
</script>
<style lang="scss" scoped>
.engagement-dashboard-card {
  border: 1px solid #c6c6c6;
  border-radius: 0px;
}
.engagement-dashboard-card-body {
  display: flex;
  height: 103px;
  overflow: hidden;
  align-items: center;
  img {
    width: 91px;
    max-width: 91px !important;
    height: 91px !important;
    margin-left: 6px;
  }
  .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    -webkit-line-clamp: 4;
    -moz-line-clamp: 4;
    -ms-line-clamp: 4;
    line-clamp: 4;
    overflow: hidden;
  }
}
.engagement-dashboard-expanded-card-body {
  height: 308px;
  overflow: hidden;
  .header {
    display: flex;
    align-items: center;
    height: 145px;
    overflow: hidden;
    .img-container {
      display: flex;
      align-items: center;
      width: 145px;
      height: 145px;
      margin-top: 5px;
      margin-left: 3px;
    }
    img {
      width: 145px;
      height: 145px;
      max-width: none;
    }
    .title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      -ms-box-orient: vertical;
      box-orient: vertical;
      -webkit-line-clamp: 4;
      -moz-line-clamp: 4;
      -ms-line-clamp: 4;
      line-clamp: 4;
      overflow: hidden;
    }
  }
  .description {
    height: 95px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    -webkit-line-clamp: 4;
    -moz-line-clamp: 4;
    -ms-line-clamp: 4;
    line-clamp: 4;
    overflow: hidden;
  }
  .stage-progress {
    display: flex;
    flex-direction: column;
  }
}
.animated-card-image,
.animated-card {
  transition: all 0.3s ease;
}

/* Adds hovering and scaling of image/content on hover */
.animated-card:hover {
  -o-box-shadow: 3px 2px 30px -10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 3px 2px 30px -10px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 3px 2px 30px -10px rgba(0, 0, 0, 0.5);
  box-shadow: 3px 2px 30px -10px rgba(0, 0, 0, 0.5);
}

.animated-card:hover .animated-card-image {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
